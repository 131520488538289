const gql = String.raw;
  
export const Catalyst = gql`
query CatalystBySlugQuery($slug: ID!) {
  entityTypeDefinition(entityTypeName: "Catalyst") {
    typeSlug
  }
  catalystBySlug(slug: $slug) {
    id name path slug __typename _schema { label pluralLabel }
    banner { openGraph { id image { id url url2x } } }
    openGraph { title image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
    description { id text }
    exampleModel { id text }
    innovations {
      id name slug path __typename
    }
  }
}
`;
