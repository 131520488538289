import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Topic } from '@shapeable/copper-connect-types';
import { DarkMode, EntityBanner, EntityBannerDefaultProps, EntityBannerProps, EntityValue } from '@shapeable/ui';
const cls = classNames('topic-banner-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicBannerLayoutProps = Classable & HasChildren & EntityBannerProps & { 
  entity?: Topic;
};

export const TopicBannerLayoutDefaultProps: Omit<TopicBannerLayoutProps, 'entity'> = {
  ...EntityBannerDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const ModeratorsStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(5)};
    margin-top: ${theme.UNIT(5)};
    color: ${theme.COLOR('bright')};
  `,
});

const ModeratorStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('bright')};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Banner: styled(EntityBanner)`${BannerStyles}`,
      Moderators: styled.div`${ModeratorsStyles}`,
        Moderator: styled(EntityValue)`${ModeratorStyles}`,
};

export const TopicBannerLayout: Shapeable.FC<TopicBannerLayoutProps> = (props) => {
  const { className, children, entity, ...rest } = props;

  const { moderators = [] } = entity;
  
  const hasModerators = !!moderators.length;

  return (
    // <DarkMode>
    <My.Container className={cls.name(className)}>
     <My.Banner 
        {...rest}
        variant='overlay'
        entity={entity}
        entityValueProps={{ showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }}}
        >
        {
          hasModerators &&
          <My.Moderators>
            {
              moderators.map(moderator => <My.Moderator key={moderator.id} variant='person' showLabel={false} subtitle={moderator?.position} subHeading={moderator?.organisation.name} entity={moderator} /> )
            }
          </My.Moderators>
        }
      </My.Banner>
    </My.Container>
    // </DarkMode>
  )
};

TopicBannerLayout.defaultProps = TopicBannerLayoutDefaultProps;
TopicBannerLayout.cls = cls;