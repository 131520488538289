import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { CatalystsProvider as Provider } from '../../components/context/catalysts-context';

const QUERY = graphql`
  query CatalystsQuery {
    platform {
      allCatalysts {
        id name path slug __typename _entityTypeName
        openGraph { id image { thumbnails { card { id url } bubble { url url2x } } } }
        innovations {
          name slug path __typename
          openGraph { id image { thumbnails { card { id url } bubble { url url2x } } } }
        }
      }
    }
  }
`;

export const CatalystsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allCatalysts' ) }>{children}</Provider>;
}