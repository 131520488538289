import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { TrendsExplorerProvider as Provider } from '../../components/context/trends-explorer-context';

const QUERY = graphql`
  query TrendsExplorerQuery {
    platform {
      allTrends { 
        __typename _schema { label pluralLabel }
        id name path outlineNumber slug _entityTypeName
        color { id name value }
        openGraph {
          title date
          image { url url2x
          thumbnails { full { url url2x } card { url url2x } }
        }
        }
        topics {
          __typename _schema { label pluralLabel }
          id name path outlineNumber slug
          color { id name value veryLightValue }
          openGraph {
            title date
            image { 
              url url2x
              thumbnails { full { url url2x } }
            }
          }
          subTopics {
            __typename _schema { label pluralLabel }
            id name path outlineNumber slug
            color { id name value }
            openGraph {
              title date
              image { url url2x }
            }
          }
        }
      }
    }
  }
`;

export const TrendsExplorerProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTrends' ) }>{children}</Provider>;
};