import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { EconomiesProvider as Provider } from '@shapeable/ui';
import { sortBy } from 'lodash';
import { Economy } from '@shapeable/copper-connect-types';

const ECONOMIES_QUERY = graphql`
  query EconomiesProvider {
    platform {
      allEconomies {
        __typename _schema { label pluralLabel }
        id path slug name
        depositsCount
        openGraph { image { url url2x thumbnails { small { url url2x } } } }
      }
    }
  }
`;

export const EconomiesProvider: React.FC<any> = ({ children }) => {
  const economies: Economy[] = platformProviderValue( useStaticQuery(ECONOMIES_QUERY), 'allEconomies' );
  // filter out economies with no deposits  

  const value = economies.filter(economy => !!economy.depositsCount);
  return <Provider value={ value }>{children}</Provider>;
};