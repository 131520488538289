import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityCard, EntityFilters, EntityGrid, ExplorerEntityGridLayout, SearchBar, WaitMessage, useSearch, useView } from '@shapeable/ui';
import { useQuery, gql } from '@apollo/client';
import { classNames } from '@shapeable/utils';
import { sortBy, compact } from 'lodash';
const cls = classNames('explorer-library-layout');

// -------- Types -------->

export type ExplorerLibraryLayoutProps = Classable & HasChildren & {
}

export const ExplorerLibraryLayoutDefaultProps: ExplorerLibraryLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`

  `,
});

const WaitMessageStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`, 
    WaitMessage: styled(WaitMessage)`${WaitMessageStyles}`,
};


export const ExplorerLibraryLayout: Shapeable.FC<ExplorerLibraryLayoutProps> = (props) => {
  const { className } = props;
  const { resultsFor } = useView('explorer', 'all');

  const { loading, data } = useQuery(
    gql`
      query { 
        allFileAssets {
          id name slug file { id extension formattedSize url typeName }
        }
        allCitations {
          __typename _schema { label pluralLabel }
          id path slug url name
          authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
        }
        allPosts {
          id path name slug
          openGraph { id image { id url } }
          type { id name }
        }
      }
  `);

  const libraryItems = sortBy([
    ...resultsFor({ type: 'Post', data: data?.allPosts, by: 'name' }),
    ...resultsFor({ type: 'Citation', data: data?.allCitations, by: 'name' }),
    ...resultsFor({ type: 'FileAsset', data: data?.allFileAssets, by: 'name' }),
  ], 'name');

  return (
      <My.Container 
      view="explorer"
      className={cls.name(className)}
      showSearchBar
      isLoading={loading}
      items={libraryItems}
      filters={
        <My.Filters 
          view="explorer"
          typeToggles={compact([
            { name: "Post", label: "Articles" },
            { name: "Citation", label: "Scientific Papers" },
            !!data?.allFileAssets.length &&
            { name: "FileAsset", label: 'Files' },
          ])} 
       />
      } 
    /> 
  )
};

ExplorerLibraryLayout.cls = cls;
ExplorerLibraryLayout.defaultProps = ExplorerLibraryLayoutDefaultProps;

{/* <My.Container className={cls.name(className)}>
<My.Filters 
  view="explorer"
  typeToggles={compact([
    { name: "Post", label: "Articles" },
    { name: "Citation", label: "Scientific Papers" },
    { name: "FileAsset", label: 'Files' },
  ])}
/>
<My.SearchBar variant='allBorders' searchTerm={searchTerm} onChange={onChange} placeholder={`Search Library`}/>
{
  loading ? 
  <My.WaitMessage /> : 
  <My.Grid items={filteredItems} desktopAutoColumns={4} spacing={2} />
}
</My.Container> */}
