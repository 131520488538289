import { Page, Post } from "@shapeable/types";
import { isImpactPage } from "../types";


export const transformPage: (entity: Page) => Page = (entity) => {
  let transformedPage = { ...entity };

  if (isImpactPage(transformedPage)) {
    // @ts-ignore
    transformedPage.typeLabel = 'Impact Strategy';
    // transformedPage._schema = { label: 'Pulse Of Society', pluralLabel: 'Debates' };

  }

  // also pull the color from related trend, so that we can show color strips
  transformedPage.color = transformedPage.trend?.color;
  
  return transformedPage;
};