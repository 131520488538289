import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints } from '@shapeable/theme';
import { useView, EntityFilters, ExplorerEntityGridLayout } from '@shapeable/ui';
import { useInnovations } from '../../hooks/use-innovations';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { compact } from 'lodash';
import { useInnovationsFilters } from '../../hooks/use-innovations-filters';
import { classNames } from '@shapeable/utils';
import { ExplorerView } from '../../types';
const cls = classNames('explorer-innovations-layout');

// -------- Types -------->

export type ExplorerInnovationsLayoutProps = Classable & HasChildren & {
}

export const ExplorerInnovationsLayoutDefaultProps: ExplorerInnovationsLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`
   
  `,
});


// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,

};

export const ExplorerInnovationsLayout: Shapeable.FC<ExplorerInnovationsLayoutProps> = (props) => {
  const { className } = props;
  
  const view = 'explorer';
  const { filters } = useView<ExplorerView>(view);

  const { themes = [], technologies = [], catalysts = [], horizonTypes = [] } = filters;

  const { selects } = useInnovationsFilters();
  const allInnovations = useInnovations();

  // TODO - hook this up to a proper query if the dataset becomes large
  const innovations = allInnovations.filter(innovation => (
    linkedMatchesFilter(innovation.trends, themes) &&
    linkedMatchesFilter(innovation.technologies, technologies) &&
    linkedMatchesFilter(innovation.catalysts, catalysts) &&
    linkedMatchesFilter(compact(innovation.horizons?.map(h => h.type)), horizonTypes)
  ));

  return (
    <My.Container 
      view={view}
      className={cls.name(className)}
      showSearchBar
      items={innovations}
      filters={
        <My.Filters view={view} selects={selects} results={allInnovations} />
      } 
    />
  );
};

ExplorerInnovationsLayout.cls = cls;
ExplorerInnovationsLayout.defaultProps = ExplorerInnovationsLayoutDefaultProps;