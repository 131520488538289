import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SliceLayoutBoundary, SiteHeaderLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayout, ComponentPropsProvider, ExplorerEntityDetailsLayoutProps, BackButtonEntityProvider, SiteHeaderProvider, } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { Topic } from '@shapeable/copper-connect-types';
import { ValueChainNavigator } from '../elements/value-chain-navigator';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { ValueChainNavigatorMain } from '../elements/value-chain-navigator-main';
import { TopicTabsLayout } from './topic-tabs-layout';
import { TopicBannerLayout } from './topic-banner-layout';
import { TopicViewLayout } from './topic-view-layout';
const cls = classNames('topic-banner-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Topic;
};

export const TopicExplorerLayoutDefaultProps: Omit<TopicExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};

    .shp--entity-banner {
      h2.shp--entity-value__name {
        font-size: ${theme.FONT_SIZE(32)};
      }
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const RadarChartStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      RadarChart: styled(ValueChainNavigator)`${RadarChartStyles}`,

};

export const TopicExplorerLayout: Shapeable.FC<TopicExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { isShown } = useValueChainMap();

  return (
    <SiteHeaderProvider value={{ 
      variant: 'default',
    }}>
    <ExplorerProvider>
    <My.Container>
      <My.Header/>
      <SliceLayoutBoundary boundary='none' >
      <My.Layout 
        entity={entity}
        className={cls.name(className)}
        asideIsRevealed={isShown}
        
        banner={
          <TopicBannerLayout entity={entity} />
        }

        //main
        tabs={
          <TopicTabsLayout entity={entity} />
        }
        content={
          <TopicViewLayout entity={entity} />
        }

        dataVizMain={
          <ValueChainNavigatorMain /> 
        }

        dataViz={
          <My.RadarChart />
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ExplorerProvider>
    </SiteHeaderProvider>
  )
};

TopicExplorerLayout.defaultProps = TopicExplorerLayoutDefaultProps;
TopicExplorerLayout.cls = cls;