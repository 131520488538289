import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityFilters, EntityTypeCardsProvider, ExplorerEntityGridLayout, OrganisationLogoCard, OrganisationMarketMapGrid, SearchBar, useOrganisations, useSearch, useView } from '@shapeable/ui';
import { compact } from 'lodash';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { useMarketMapFilters } from '../../hooks/use-market-map-filters';
import { Organisation } from '@shapeable/copper-connect-types';
import { classNames } from '@shapeable/utils';

const cls = classNames('explorer-market-map-layout');

// -------- Types -------->

export type ExplorerMarketMapLayoutProps = Classable & HasChildren & {
}

export const ExplorerMarketMapLayoutDefaultProps: ExplorerMarketMapLayoutProps = {

};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerMarketMapLayout: Shapeable.FC<ExplorerMarketMapLayoutProps> = (props) => {
  const { className } = props;

  const allOrganisations = useOrganisations() as Organisation[];
  const marketMapFilters = useMarketMapFilters('explorer-community');
  const { selects } = marketMapFilters;

  const view = 'explorer';
  const { filters } = useView(view);
  
  const { countries = [], type = [], technologies = [], } = filters;

  const organisations = allOrganisations.filter(organisation => (
    linkedMatchesFilter(organisation.countries, countries) && 
    linkedMatchesFilter(compact([organisation?.type]), type) &&
    linkedMatchesFilter(compact(organisation?.technologies), technologies)
  ));

  return (
    <EntityTypeCardsProvider value={{
      Organisation: OrganisationLogoCard,
    }}>
    <My.Container 
      className={cls.name(className)} 
      view={view}
      showSearchBar
      items={organisations}
      landscapeAutoColumns={3}
      tabletAutoColumns={7}
      desktopAutoColumns={7}
      desktopFullHdAutoColumns={7}
      desktopLargeAutoColumns={7}
      desktopFullHdMinColumns={7}
      desktopLargeMinColumns={7}
      spacing={4}
      filters={
        <My.Filters 
          view={view}
          results={allOrganisations}
          selects={selects}
        />
      }
    />
    </EntityTypeCardsProvider>
  )
};

ExplorerMarketMapLayout.cls = cls;
ExplorerMarketMapLayout.defaultProps = ExplorerMarketMapLayoutDefaultProps;