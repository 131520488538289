import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme, ColorTokens } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { CellLabel, ContentNode, EntityList, ExplorerAsideSection, UiComponentName, useComponentProps, useLang, useTrends } from '@shapeable/ui';

const cls = classNames('entity-conversations-aside-layout');

// -------- Types -------->

// -------- Props -------->

export type EntityConversationsAsideLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const EntityConversationsAsideLayoutDefaultProps: Omit<EntityConversationsAsideLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(6)};
  `,
});

const OverviewStyles = breakpoints({
  base: css`
    
    color: ${theme.COLOR('text')};
  `,
});

const ConversationsStyles = breakpoints({
  base: css`
    
  `,
});

const LabelStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text-mid')};
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const ConversationTrendsListStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  NAME: 'EntityConversationsAsideLayout' as UiComponentName,

  Container: styled(ExplorerAsideSection)<ContainerProps>`${ContainerStyles}`,
    Overview: styled(ContentNode)`${OverviewStyles}`,
    Conversations: styled.div`${ConversationsStyles}`,
    Label: styled(CellLabel)`${LabelStyles}`,
    ConversationTrendsList: styled(EntityList)`${ConversationTrendsListStyles}`,
};

export const EntityConversationsAsideLayout: Shapeable.FC<EntityConversationsAsideLayoutProps> = (props) => {
  const { className, children, entity } = useComponentProps(My.NAME, props, EntityConversationsAsideLayoutDefaultProps) as EntityConversationsAsideLayoutProps;
  
  const t = useLang();

  const overviewText: TContentNode = {
    text: 'Select a Conversation to participate in, by Value Theme below',
  };

  const themes = useTrends();

  return (
    <My.Container className={cls.name(className)}>
      <My.Overview entity={overviewText} />
      <My.Conversations>
        <My.Label >{t('Conversations:')}</My.Label>
        <My.ConversationTrendsList items={themes} />
      </My.Conversations>
    {children}
    </My.Container>
  )
};

EntityConversationsAsideLayout.cls = cls;