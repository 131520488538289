import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { OrganisationsProvider as Provider } from '@shapeable/ui';

const QUERY = graphql`
  query OrganisationsQuery {
    platform {
      allOrganisations {
        id name path slug __typename _entityTypeName
        openGraph {
          title date
          image { url url2x }
        }
        type { slug name }
        logo { url url2x }
        logoInverted { url url2x }
        countries { id slug name }
        technologies { id slug name openGraph { image { url url2x } } }
        location {
          __typename id slug name
          latitude longitude
          openGraph { id image { id url url2x } }
        }
      }
    }
  }
`

export const OrganisationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allOrganisations' ) }>{children}</Provider>;
};