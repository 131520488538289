import React from 'react';
import { LinearNavigationProvider } from './linear-navigation-provider';
import { ActiveEntityProvider, SiteFooterProvider, useAuth } from '@shapeable/ui';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react';
import { Page } from '@shapeable/types';

export const WITH_AUTHENTICATION_REQUIRED_OPTIONS: WithAuthenticationRequiredOptions = {
  loginOptions: {
    appState: { targetUrl: '/explorer' },
  }
};

export const PAGE_NAVIGATOR: Page = {
  id: 'explorer',
  slug: 'explorer',
  path: '/explorer',
};

const ExplorerProviderComponent: React.FC<any> = ({ children }) => {
  return (
    <SiteFooterProvider value={{ boundary: 'none' }}>
    <ActiveEntityProvider value={PAGE_NAVIGATOR}>
    <LinearNavigationProvider>
    {children}
    </LinearNavigationProvider>
    </ActiveEntityProvider>
    </SiteFooterProvider>
  );
};

let authRequired = true;

if (typeof window !== 'undefined') {
  if (window.location.href.indexOf('?key=shap3copper') > -1) {  
    authRequired = false;
  }
}

export const ExplorerProvider = authRequired ? withAuthenticationRequired(ExplorerProviderComponent) : ExplorerProviderComponent;
