import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Post, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Trend } from '@shapeable/copper-connect-types';
import { ContentEntityIntro, ContentNode, ContentTabs, DottedLine, EntityGrid, ExplorerEntityGridLayout, ExplorerView, NextPageLink, SearchBar, useSearch, useView } from '@shapeable/ui';
import { CommodityGroupDepositList } from './commodity-group-deposit-list';
import { GptLayout } from '@shapeable/gpt';
import { TrendView } from '../../types';
import { useCommodityGroups } from '../../hooks/use-commodity-groups';
import { useTrendViewDefinition } from '../../hooks/use-trend-view-definition';
import { transformPage } from '../../utils/transforms';
const cls = classNames('trend-view-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendViewLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendViewLayoutDefaultProps: Omit<TrendViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(7)} 0;
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(5)} 0;
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});


const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    padding-top: ${theme.UNIT(1)};
    margin-top: ${theme.UNIT(2)}; 
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em;
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(5)} 0;
    
  `,
});

const EntityGridStyles = breakpoints({
  base: css`
    .shp--card {
      background-color: ${theme.COLOR('light')};
    }
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    .shp--gpt-prompt {
      &:first-child {
        border-top: none;
      }
    };
  `,
});

const DepositsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const SearchBarStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h2`${ContentTitleStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,

      Deposits: styled(CommodityGroupDepositList)`${DepositsStyles}`,

      SearchBar: styled(SearchBar)`${SearchBarStyles}`,

      LibraryGrid: styled(ExplorerEntityGridLayout)`${EntityGridStyles}`,

      EntityGrid: styled(EntityGrid)`${EntityGridStyles}`,
      Tabs: styled(ContentTabs)`${TabsStyles}`,

      NextLink: styled(NextPageLink)`${NextLinkStyles}`,

      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};

export const TrendViewLayout: Shapeable.FC<TrendViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { description, posts = [], pages = [] } = entity;

  const { hasDeposits, defaultView, copper, libraryItems } = useTrendViewDefinition(entity);

  const { searchTerm, setSearchTerm, filteredItems } = useSearch(libraryItems);

  const onChange = (name: string) => { 
    setSearchTerm(name);
   };

  const { showView } = useView<TrendView>(entity.path, defaultView);
  
  return (
    <My.Container className={cls.name(className)}>
      {
        showView('copper-ai') && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        showView('overview') && 
        <My.Section>
          <My.Intro entity={entity} />
          <My.Description entity={description as TContentNode} />
        </My.Section>
      }
       {
        showView('deposits') &&
        <My.Section>
          {
            hasDeposits && 
            <CommodityGroupDepositList entity={copper} />
          }
        </My.Section>
      }
      {
        showView('library') && 
        <My.Section>
          <My.SearchBar variant='allBorders' searchTerm={searchTerm} onChange={onChange} placeholder={`Search Library`}/>
          <My.EntityGrid spacing={1} items={filteredItems}/>
        </My.Section>
      }
      <DottedLine height={2} />
      <My.NextLink />
    </My.Container>
  )
};

TrendViewLayout.defaultProps = TrendViewLayoutDefaultProps;
TrendViewLayout.cls = cls;