import React from 'react';
import { IconComponent } from '@shapeable/types'

export const TechnologyIcon: IconComponent = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 96 96">
  <path d="M48 25.0435C49.1526 25.0435 50.087 24.1091 50.087 22.9565C50.087 21.8039 49.1526 20.8696 48 20.8696C46.8474 20.8696 45.9131 21.8039 45.9131 22.9565C45.9131 24.1091 46.8474 25.0435 48 25.0435Z" />
  <path d="M48 16.6956C49.1526 16.6956 50.087 15.7613 50.087 14.6087C50.087 13.4561 49.1526 12.5217 48 12.5217C46.8474 12.5217 45.9131 13.4561 45.9131 14.6087C45.9131 15.7613 46.8474 16.6956 48 16.6956Z" />
  <path d="M37.5652 25.252C37.9826 26.2955 39.2348 26.9216 40.2782 26.5042C41.3217 26.0868 41.9478 24.8347 41.5304 23.7912C41.113 22.7477 39.8608 22.1216 38.8174 22.539C37.7739 22.9564 37.1478 23.9999 37.5652 25.252Z" />
  <path d="M37.3565 18.5742C38.4 18.1568 39.026 16.9047 38.6087 15.8612C38.1913 14.8177 36.9391 14.1916 35.8956 14.609C34.8521 15.0264 34.226 16.2786 34.6434 17.3221C35.0608 18.3655 36.313 18.9916 37.3565 18.5742Z" />
  <path d="M33.1827 30.4693C34.0174 29.6345 34.2261 28.3823 33.3913 27.5476C32.5566 26.7128 31.3044 26.5041 30.4696 27.3389C29.6348 28.1736 29.4261 29.4258 30.2609 30.2606C31.0957 31.0954 32.3479 31.0954 33.1827 30.4693Z" />
  <path d="M25.2522 20.8691C24.4174 21.7039 24.2087 22.956 25.0435 23.7908C25.8783 24.6256 27.1305 24.8343 27.9652 23.9995C28.8 23.1647 29.0087 21.9125 28.1739 21.0778C27.3392 20.243 26.087 20.0343 25.2522 20.8691Z" />
  <path d="M27.3391 33.5999C26.2957 32.9738 25.0435 33.3912 24.4174 34.4347C23.7913 35.4782 24.2087 36.7303 25.2522 37.3564C26.2957 37.9825 27.5478 37.5651 28.1739 36.5216C28.8 35.4782 28.3826 34.226 27.3391 33.5999Z" />
  <path d="M20.0348 29.4259C18.9913 28.7999 17.7392 29.2172 17.1131 30.2607C16.6957 31.3042 17.1131 32.5564 17.9479 33.1825C18.9913 33.8085 20.2435 33.3912 20.8696 32.3477C21.4957 31.3042 21.0783 30.052 20.0348 29.4259Z" />
  <path d="M23.7913 41.5309C22.7478 41.3222 21.4957 42.1569 21.287 43.2004C21.0783 44.2439 21.913 45.4961 22.9565 45.7048C24 45.9135 25.2522 45.0787 25.4609 44.0352C25.6696 42.783 24.8348 41.7395 23.7913 41.5309Z" />
  <path d="M15.4435 40.0695C14.4 39.8609 13.1478 40.6956 12.9391 41.7391C12.7304 42.7826 13.5652 44.0348 14.6087 44.2435C15.6522 44.4522 16.9043 43.6174 17.113 42.5739C17.3217 41.5304 16.6956 40.2782 15.4435 40.0695Z" />
  <path d="M81.1825 51.7567C80.1391 51.548 78.8869 52.3828 78.6782 53.4263C78.4695 54.4698 79.3043 55.722 80.3478 55.9307C81.3912 56.1394 82.6434 55.3046 82.8521 54.2611C83.0608 53.0089 82.4347 51.9654 81.1825 51.7567Z" />
  <path d="M73.0434 50.2954C71.9999 50.0867 70.7477 50.9215 70.539 51.965C70.3303 53.0085 71.1651 54.2607 72.2086 54.4694C73.2521 54.678 74.5042 53.8433 74.7129 52.7998C74.9216 51.5476 74.0869 50.5041 73.0434 50.2954Z" />
  <path d="M22.9565 50.2954C21.913 50.5041 21.0783 51.5476 21.287 52.7998C21.4957 53.8433 22.5391 54.678 23.7913 54.4694C24.8348 54.2607 25.6696 53.2172 25.4609 51.965C25.2522 50.9215 24.2087 50.0867 22.9565 50.2954Z" />
  <path d="M14.8174 51.7567C13.7739 51.9654 12.9392 53.0089 13.1479 54.2611C13.3566 55.3046 14.4 56.1394 15.6522 55.9307C16.6957 55.722 17.5305 54.6785 17.3218 53.4263C16.9044 52.3828 15.8609 51.548 14.8174 51.7567Z" />
  <path d="M81.1826 44.2437C82.226 44.035 83.0608 42.9915 82.8521 41.7393C82.6434 40.4871 81.6 39.8611 80.3478 40.0698C79.0956 40.2785 78.4695 41.3219 78.6782 42.5741C79.0956 43.6176 80.1391 44.4524 81.1826 44.2437Z" />
  <path d="M72.2086 41.53C71.1651 41.7387 70.3303 42.7822 70.539 44.0344C70.7477 45.0779 71.7912 45.9126 73.0434 45.7039C74.0869 45.4952 74.9216 44.4518 74.7129 43.1996C74.5042 41.9474 73.4608 41.3213 72.2086 41.53Z" />
  <path d="M78.6783 30.2609C78.0522 29.2174 76.8 29.0087 75.7565 29.4261C74.7131 30.0522 74.5044 31.3044 74.9218 32.3478C75.5478 33.3913 76.8 33.6 77.8435 33.1826C78.887 32.5565 79.3044 31.3044 78.6783 30.2609Z" />
  <path d="M71.5826 34.4348C70.9565 33.3913 69.7043 33.1826 68.6608 33.6C67.6174 34.2261 67.4087 35.4782 67.8261 36.5217C68.4521 37.5652 69.7043 37.7739 70.7478 37.3565C71.7913 36.7304 72 35.4782 71.5826 34.4348Z" />
  <path d="M71.1653 23.7908C72.0001 22.956 71.7914 21.4952 70.9566 20.8691C70.1218 20.0343 68.6609 20.243 68.0348 21.0778C67.2001 21.9125 67.4088 23.3734 68.2435 23.9995C69.0783 24.8343 70.3305 24.6256 71.1653 23.7908Z" />
  <path d="M65.7391 30.2606C66.5739 29.4258 66.3652 27.9649 65.5304 27.3389C64.6957 26.5041 63.2348 26.7128 62.6087 27.5476C61.7739 28.3823 61.9826 29.8432 62.8174 30.4693C63.6522 31.0954 64.9043 31.0954 65.7391 30.2606Z" />
  <path d="M58.6434 18.5742C59.6869 18.9916 60.9391 18.3655 61.3565 17.3221C61.7738 16.2786 61.1478 15.0264 60.1043 14.609C59.0608 14.1916 57.8086 14.8177 57.3912 15.8612C56.9738 16.9047 57.5999 18.1568 58.6434 18.5742Z" />
  <path d="M55.9305 26.5042C56.9739 26.9216 58.2261 26.2955 58.6435 25.252C59.0609 24.2086 58.4348 22.9564 57.1826 22.539C55.9305 22.1216 54.887 22.7477 54.4696 23.7912C54.0522 24.8347 54.6783 26.0868 55.9305 26.5042Z" />
  <path d="M48 0C21.4957 0 0 21.4957 0 48C0 74.5043 21.4957 96 48 96C74.5043 96 96 74.5043 96 48C96 21.4957 74.5043 0 48 0ZM48 87.6522C26.087 87.6522 8.34783 69.913 8.34783 48C8.34783 26.087 26.087 8.34783 48 8.34783C69.913 8.34783 87.6522 26.087 87.6522 48C87.6522 69.913 69.913 87.6522 48 87.6522Z" />
  <path d="M48 29.2174C47.7913 29.2174 47.5826 29.2174 47.3739 29.2174C37.3565 29.4261 29.2174 37.9826 29.2174 48C29.2174 60.5218 39.6522 66.7826 39.6522 68.8696H38.6087C37.9826 68.8696 37.5652 69.287 37.5652 69.9131C37.5652 70.5391 37.9826 70.9565 38.6087 70.9565H39.6522V73.0435H38.6087C37.9826 73.0435 37.5652 73.4609 37.5652 74.087C37.5652 74.7131 37.9826 75.1305 38.6087 75.1305H39.6522V77.2174C39.6522 78.6783 40.487 80.1391 41.7391 80.7652V81.3913C41.7391 82.6435 42.5739 83.4783 43.8261 83.4783C45.0783 83.4783 45.9131 82.6435 45.9131 81.3913H50.087C50.087 82.6435 50.9218 83.4783 52.1739 83.4783C53.4261 83.4783 54.2609 82.6435 54.2609 81.3913V80.7652C55.5131 80.1391 56.3478 78.6783 56.3478 77.2174V75.1305H57.3913C58.0174 75.1305 58.4348 74.7131 58.4348 74.087C58.4348 73.4609 58.0174 73.0435 57.3913 73.0435H56.3478V70.9565H57.3913C58.0174 70.9565 58.4348 70.5391 58.4348 69.9131C58.4348 69.287 58.0174 68.8696 57.3913 68.8696H56.3478C56.3478 66.7826 66.7826 60.5218 66.7826 48C66.7826 37.5652 58.4348 29.2174 48 29.2174ZM43.8261 70.9565H48V73.0435H43.8261V70.9565ZM48 75.1305V77.2174H43.8261V75.1305H48ZM55.5131 63.2348C53.6348 65.1131 52.1739 66.5739 52.1739 68.8696H43.8261C43.8261 66.5739 42.3652 65.1131 40.487 63.0261C37.7739 59.8957 33.3913 55.3044 33.3913 48C33.3913 40.0696 39.6522 33.6 47.5826 33.3913C47.7913 33.3913 47.7913 33.3913 48 33.3913C56.1391 33.3913 62.6087 39.8609 62.6087 48C62.6087 55.3044 58.2261 60.1044 55.5131 63.2348Z" />
  <path d="M59.2696 47.3739L53.0087 39.0261C52.8 38.6087 52.3826 38.6087 51.9652 38.6087C51.5478 38.6087 51.3391 39.0261 51.1305 39.4435L49.6696 46.9565H46.1218L44.6609 39.4435C44.6609 39.0261 44.2435 38.6087 43.8261 38.6087C43.4087 38.6087 42.9913 38.6087 42.7826 39.0261L36.5218 47.3739C36.3131 47.7913 36.3131 48.2087 36.5218 48.4174C36.7305 48.6261 37.1478 49.0435 37.3565 49.0435H44.2435L46.7478 62.8174C46.7478 63.2348 47.3739 63.6522 47.7913 63.6522C48.2087 63.6522 48.6261 63.2348 48.8348 62.8174L51.3391 49.0435H58.4348C58.8522 49.0435 59.2696 48.8348 59.2696 48.4174C59.2696 48 59.4783 47.7913 59.2696 47.3739ZM39.6522 46.9565L43.2 42.1565L44.0348 46.9565H39.6522ZM48 56.7652L46.5391 49.0435H49.2522L48 56.7652ZM51.9652 46.9565L52.8 42.1565L56.3478 46.9565H51.9652Z" />
  </svg>
);