import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ExplorerProvider } from '../providers/explorer-provider';
import { EntityConversationRoute, ExplorerEntityDetailsLayout } from '@shapeable/ui';
import { EntityConversationsAsideLayout } from '../entities/entity-conversations-aside-layout';
const cls = classNames('radar-home-layout');

// -------- Types -------->

export type ExplorerConversationsLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerConversationsLayoutDefaultProps: Omit<ExplorerConversationsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
    background: ${theme.COLOR('bright')};
  `,
});

const LayoutStyles = breakpoints({
  base: css`

  `,
  desktop: css`
    
  `,
});

const ConversationsStyles = breakpoints({
  base: css`
    height: 100vh;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,

    Conversations: styled(EntityConversationRoute)`${ConversationsStyles}`,
};

export const ExplorerConversationsLayout: Shapeable.FC<ExplorerConversationsLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  return (
    <ExplorerProvider>

    <My.Container className={cls.name(className)}>
      <My.Layout
        asideMaxWidth={800}
        asideHeaderProps={{ navigationProps: { showIcon: false }}}
        entity={entity}
        isAsideSticky
        stickyTopOffset={106}
        asideIsRevealed={false}
        
        content={
          // <ExplorerHomeViewLayout entity={entity} />
            <My.Conversations entity={entity} />
        }

        metaHead={
          <EntityConversationsAsideLayout entity={entity} />
        }

      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};

ExplorerConversationsLayout.defaultProps = ExplorerConversationsLayoutDefaultProps;
ExplorerConversationsLayout.cls = cls;