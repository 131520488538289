import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, ColorTokens, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
import { compact } from 'lodash';
import { TopicView } from '../../types';
import { Topic } from '@shapeable/copper-connect-types';
const cls = classNames('topic-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicTabsLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicTabsLayoutDefaultProps: Omit<TopicTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->


const TabsStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,

};

export const TopicTabsLayout: Shapeable.FC<TopicTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { feedEntries = [], pages = [], posts = [], citations = [], 
    color } = entity;

  const contentTabColors: ColorTokens.Any = {
    hover: {
      text: color?.value,
      fill: color?.value,
    },
    active: {
      text: color?.value,
      fill: color?.value,
      border: color?.value,
    }
  };

  const libraryItems = compact([...posts, ...feedEntries, ...pages, ...citations ]);

  const hasLibrary = !!libraryItems.length;

  const tabs: ContentTabSpec<TopicView>[] = compact([
    {
      id: 'copper-ai',
      icon: <AiUpperCaseIconGlyph size={30} />
    },
    {
      id: 'deep-dive',
      label: 'Deep Dive',
    },
    !!entity?.innovations?.length && {
      id: 'innovations',
      label: 'Innovations',
    },
    hasLibrary && {
      id: 'library',
      label: 'Library',
    },
  ]);

  const view = entity.path;
  const { activeView, setActiveView } = useView<TopicView>(view, 'deep-dive');
  
  return (
    <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={contentTabColors} />
  )
};

TopicTabsLayout.defaultProps = TopicTabsLayoutDefaultProps;
TopicTabsLayout.cls = cls;