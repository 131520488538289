const gql = String.raw;
  
export const Trend = gql`
query TrendBySlugQuery($slug: ID!) {
  entityTypeDefinition(entityTypeName: "Trend") {
    typeSlug
  }
  trendBySlug(slug: $slug) {
    
      __typename _schema { label pluralLabel }
      id slug name __typename outlineNumber
      color { __typename id slug name value }
      description { text }
      intro { text }
      openGraph { 
        title description { plain }
        image { url thumbnails { large { url } } }
      }
      banner {
        id
        thumbnail { url } 
        image { 
          thumbnails {
            mainBanner { id url }
          }
          url 
        }
        
    
      }
      feedEntries {
        id slug path hashPath url name __typename _schema { label pluralLabel }
        types { id name slug }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
      }
      posts {
        id slug name __typename path
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
      }
      citations {
        __typename _schema { label pluralLabel }
        id path slug url name
        authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
      }
      topics {
        id name outlineNumber slug path __typename 
        # trendIntro { text }
        intro { text(truncate: {ellipsis: "...", words: 45}) }
        color { id name value } 
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      pages {
        __typename id name path slug title subtitle pretitle 
        openGraph { description { text } image { url } }
        intro { text }
        orderNumber
        type { id name slug }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
    
    embeds {
      posts {
        id name slug path
        openGraph {
          title description { plain }
          image { url url2x type }
        }
      }
    }
  }
}
`;
