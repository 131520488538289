import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Shapeable } from '@shapeable/types';
import { breakpoints } from '@shapeable/theme';
import { useView, EntityFilters, ExplorerEntityGridLayout } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { useQuery, gql } from '@apollo/client';
import { ExplorerView } from '../../types';
const cls = classNames('explorer-impact-strategies');

// -------- Types -------->

export type ExplorerImpactStrategiesProps = Classable & HasChildren & {
}

export const ExplorerImpactStrategiesDefaultProps: ExplorerImpactStrategiesProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`

  `,
});

// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerImpactStrategies: Shapeable.FC<ExplorerImpactStrategiesProps> = (props) => {
  const { className } = props;
  const { resultsFor } = useView<ExplorerView>('explorer', 'impact-strategies');
  const { loading, data } = useQuery(
    gql`
      query {
        listPages(filter: { types: { in: ["impact-strategy"]} }) {
          results {
            name path slug __typename _schema { label pluralLabel }
            openGraph { id image { id url } }
          }
        }
        allCatalysts {
          id name path slug __typename _schema { label pluralLabel }
          openGraph { id image { thumbnails { card { id url } } } }
        }
      }
  `);

  const pages = data?.listPages?.results || [];
  const catalystsData = data?.allCatalysts || [];

  // patch the schema label, since these aren't true "ImpactStrategy" entities, 
  // they're pages bound to a sub-type

  const pagesWithName = pages.map((page: Page) => {
    return {
      ...page,
      _schema: {
        label: 'Impact Strategy',
      }
    }
  })

  const items = [
    ...(resultsFor({ type: 'Catalyst', data: catalystsData, by: 'name' })), 
    ...(resultsFor({ type: 'ImpactStrategy', data: pagesWithName, by: 'name' })), 
  ];

  return (
    <My.Container 
      className={cls.name(className)} 
      showSearchBar
      items={items}
      spacing={2}
      isLoading={loading}
      filters={
        <My.Filters 
          view="explorer"
          typeToggles={[
            { name: 'Catalyst' },
            { name: "ImpactStrategy", label: "Impact Strategies" },
          ]}
        />
      }
    />  
  )
};

ExplorerImpactStrategies.cls = cls;
ExplorerImpactStrategies.defaultProps = ExplorerImpactStrategiesDefaultProps;