import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Post, Shapeable } from '@shapeable/types';
import { breakpoints, ColorTokens, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
import { compact } from 'lodash';
import { Trend } from '@shapeable/copper-connect-types';
import { TrendView } from '../../types';
import { useTrendViewDefinition } from '../../hooks/use-trend-view-definition';
const cls = classNames('trend-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendTabsLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendTabsLayoutDefaultProps: Omit<TrendTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->


const TabsStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,

};

export const TrendTabsLayout: Shapeable.FC<TrendTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { color } = entity;

  const contentTabColors: ColorTokens.Any = {
    hover: {
      text: color?.value,
      fill: color?.value,
    },
    active: {
      text: color?.value,
      fill: color?.value,
      border: color?.value,
    }
  };

  const { isDiscoveryTrend, hasLibrary, defaultView } = useTrendViewDefinition(entity);

  const tabs: ContentTabSpec<TrendView>[] = compact([
    {
      id: 'copper-ai',
      icon: <AiUpperCaseIconGlyph size={30} />
    },
    {
      id: 'overview',
      label: 'Overview',
    },
    isDiscoveryTrend &&
    {
      id: 'deposits',
      label: 'Deposits',
    },
    hasLibrary &&
    {
      id: 'library',
      label: 'Library',
    },
  ]);

  const { activeView, setActiveView } = useView<TrendView>(entity.path, defaultView);
  
  return (
    <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={contentTabColors} />
  )
};

TrendTabsLayout.defaultProps = TrendTabsLayoutDefaultProps;
TrendTabsLayout.cls = cls;