import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ExplorerEntityDetailsLayout, useEntity, SiteHeaderLayout } from '@shapeable/ui';
import { ExplorerGptViewLayout } from '@shapeable/gpt';
import { ExplorerGptBannerLayout } from './explorer-gpt-banner-layout';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { ValueChainNavigatorMain } from '../elements/value-chain-navigator-main';
import { ValueChainNavigator } from '../elements/value-chain-navigator';
import { ExplorerProvider } from '../providers/explorer-provider';
const cls = classNames('gpt-layout');

// -------- Types -------->

export type ExplorerGptLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerGptLayoutDefaultProps: Omit<ExplorerGptLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: ${theme.COLOR('bright')};
  `,
});

const SiteHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,

});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${SiteHeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
    
};

export const ExplorerGptLayout: Shapeable.FC<ExplorerGptLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { isShown } = useValueChainMap();

  return (
    <ExplorerProvider>
    <My.Container className={cls.name(className)}>
      <My.Layout
        entity={entity}
        asideMaxWidth={800}
        asideHeaderProps={{ navigationProps: { showIcon: false }}}
        isAsideSticky={false}
        asideIsRevealed={isShown}

        //main
        banner={
          <ExplorerGptBannerLayout entity={entity} />
        }
        content={
          <ExplorerGptViewLayout />
        }
        dataVizMain={
          <ValueChainNavigatorMain />
        }

        //aside
        dataViz={
          <ValueChainNavigator />
        }
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};

ExplorerGptLayout.defaultProps = ExplorerGptLayoutDefaultProps;
ExplorerGptLayout.cls = cls;