const gql = String.raw;
  
export const Innovation = gql`
query InnovationBySlugQuery($slug: ID!) {
  entityTypeDefinition(entityTypeName: "Innovation") {
    typeSlug
  }
  innovationBySlug(slug: $slug) {
    __typename _schema { label pluralLabel }
    openGraph { title description { text(truncate: { words: 20 }) } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
    id slug name
    topic { 
      id slug name path
      trend {
        id slug name path
      } 
    }
    description { id text }
    example { id text }
    technologies {
      id name slug _schema { label pluralLabel }
      openGraph { image { id url url2x } }
      organisations { 
        id name slug url
        type { id name slug }
        technologies { id name slug }
        logo { url url2x }
        openGraph { id image { id url url2x thumbnails { bubble { url url2x } } } }
      }
    }
    catalysts {
      id name path slug _schema { label pluralLabel }
      openGraph { image { url url2x } }
      description { text }
    }
    impactResultTypes {
      id name slug _schema { label pluralLabel }
      openGraph { image { url url2x } }
    }
    commodityGroups {
      id name slug _schema { label pluralLabel }
      openGraph { image { url url2x } }
    }
    barriers {
      id name slug _schema { label pluralLabel }
      openGraph { image { url url2x } }
    }
    horizons {
      name path slug _schema { label pluralLabel }
      type { id name value }
      description { text }
    }
    sdgs {
      id name slug _schema { label pluralLabel }
      openGraph { image { url url2x } }
    }
  }
}
`;
