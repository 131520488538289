import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useOrganisations, usePeople, StakeholderBioCellGrid, useOrganisationTypes, useEconomies, useView, EntityFilters, ExplorerEntityGridLayout } from '@shapeable/ui';
import { compact } from 'lodash';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { Organisation } from '@shapeable/copper-connect-types';
import { useCommunityFilters } from '../../hooks/use-community-filters';
import { classNames } from '@shapeable/utils';
const cls = classNames('explorer-community-layout');

// -------- Types -------->

export type ExplorerCommunityLayoutProps = Classable & HasChildren & {
}

export const ExplorerCommunityLayoutDefaultProps: ExplorerCommunityLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`

  `,
});


// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerCommunityLayout: Shapeable.FC<ExplorerCommunityLayoutProps> = (props) => {
  const { className } = props;
  const organisationTypes = useOrganisationTypes();
  const economies = useEconomies();
  const view = 'explorer';
  const { resultsFor, filters } = useView(view);
  const { types = [], countries = [] } = filters;

  const allPeople = usePeople();
  const allOrganisations = useOrganisations();
  const community = useCommunityFilters('explorer-community');
  const { selects, typeToggles } = community;

  // TODO - hook this up to a proper query if the dataset becomes large
  const people = allPeople.filter(person => (
    linkedMatchesFilter(person.economies, countries)
  ));

  const organisations = allOrganisations.filter(organisation => (
    linkedMatchesFilter(organisation.countries, countries) &&
    linkedMatchesFilter(compact([organisation?.type]), types)
  ));

  const stakeholders = [
    ...(resultsFor({ type: 'Organisation', data: organisations, by: 'name' })), 
    ...(resultsFor({ type: 'Person', data: people, by: 'name' })), 
  ];
  
  return (
    <My.Container 
      className={cls.name(className)} 
      view={view}
      items={stakeholders}
      showSearchBar
      filters={
        <My.Filters
        view={view}
        results={[...allPeople, ...allOrganisations]}
        selects={compact([
          economies.length &&
          {
            name: 'countries',
            items: economies,
            pluralLabel: 'Countries',
          },
          {
            name: 'types',
            items: organisationTypes,
            pluralLabel: 'Stakeholder Types',
            getResultFieldSlugs: (result: Organisation) => [result.type?.slug],
          },
          ])}
          typeToggles={[
            { name: 'Organisation' },
            { name: 'Person' },
          ]}
        />
       }
    />
  );
};

ExplorerCommunityLayout.cls = cls;
ExplorerCommunityLayout.defaultProps = ExplorerCommunityLayoutDefaultProps;