
import { compact } from "lodash";
import { TrendView } from "../types";
import { CommodityGroup, Trend } from "@shapeable/copper-connect-types";
import { useCommodityGroups } from "./use-commodity-groups";
import { Entity } from "@shapeable/types";
import { transformPage } from "../utils/transforms";


type TrendViewDefiniton = {
  hasLibrary: boolean;
  hasDeposits: boolean;
  isDiscoveryTrend: boolean;
  copper: CommodityGroup;
  libraryItems: Entity[];
  defaultView: TrendView;
};

export const useTrendViewDefinition: (trend: Trend) => TrendViewDefiniton = 
  (entity: Trend) => {
    const { feedEntries = [], pages = [], posts = [], citations = [] } = entity;

    const transformedPages = pages.map(transformPage);

    const libraryItems = compact([...posts, ...feedEntries, ...transformedPages, ...citations ]);

    const commodityGroups = useCommodityGroups();
    const copper = commodityGroups.find(item => item.slug === 'copper');

    const hasDeposits = !!copper;
    const hasLibrary = !!libraryItems.length;
    const isDiscoveryTrend = entity.slug === 'discovery';

    const defaultView: TrendView = isDiscoveryTrend ? 'deposits' : 'overview';

    return {
      hasLibrary,
      hasDeposits,
      isDiscoveryTrend,
      copper,
      libraryItems,
      defaultView
    };
    
  };