import { Page } from "@shapeable/types";

export type ExplorerView = 'ai' | 'home' | 'conversations' | 'value-chain' | 'commodities' | 'impact-strategies' | 'community' | 'innovations' | 'stakeholder-map' | 'library';
export type TrendView = 'copper-ai' | 'overview' | 'deposits' | 'library';
export type TopicView = 'copper-ai' | 'deep-dive' | 'innovations' | 'library';
export type InnovationView = 'copper-ai' | 'details' | 'community' | 'stakeholder-map' | 'library';
export type CommodityGroupView = 'copper-ai' | 'overview' | 'deposits' | 'library';

export type Impact = Page & {
};

const isPageType = (entity: Page, type: string) => {
  return entity.type?.slug === type || entity.types?.[0]?.slug === type;
}

// type guards
export const isImpactPage = (entity: Page): entity is Impact => isPageType(entity, 'impact-strategy');