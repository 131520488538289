import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { CommodityGroupsProvider as Provider } from '../../components/context/commodity-groups-context';

const QUERY = graphql`
  query CommodityGroupQuery {
    platform {
      allCommodityGroups {
        id name slug path __typename _entityTypeName
        openGraph {
          title date description { plain }
          image { url url2x }
        }
      }
    }
  }
`;

export const CommodityGroupsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allCommodityGroups' ) }>{children}</Provider>;
}