import React from 'react';
import { LinearNavigationProvider as CoreProvider, LinearNavigationItem, useEntity, useTopics, useTrends } from "@shapeable/ui"
import { Trend } from '@shapeable/copper-connect-types';
import { compact, sortBy } from 'lodash';

export const LinearNavigationProvider: React.FC<any> = ({ children }) => {

  const trends = useTrends() as Trend[];
  const items: LinearNavigationItem[] = [];

  for (const trend of trends) {
    items.push({
      id: trend.id,
      name: compact([trend.outlineNumber, trend.name]).join(" - "),
      depth: 0,
      path: trend.path,
      color: trend.color,
      slug: trend.slug,
    });

    for (const topic of sortBy(trend.topics, 'outlineNumber')) {
      items.push({
        id: topic.id,
        name: compact([topic.outlineNumber, topic.name]).join(" - "),
        depth: 1,
        path: topic.path,
        slug: topic.slug,
        _schema: topic._schema,
      });

      // for (const innovation of topic.innovations) {
      //   items.push({
      //     id: innovation.id,
      //     name: ['Innovation', innovation.name].join(" - "),
      //     depth: 2,
      //     path: innovation.path,
      //     slug: innovation.slug,
      //     _schema: innovation._schema,
      //   });
      // }

    }
  }

  return (
    <CoreProvider value={items}>
    {children}
    </CoreProvider>
  )
}