import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteFooterLayout, SiteFooterProvider, SiteHeaderLayout, SiteHeaderProvider } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { useEntity } from '@shapeable/ui';
import { ExplorerIndexLayout } from '../explorer/explorer-index-layout';
import { ExplorerProvider } from '../providers/explorer-provider';
const cls = classNames('page-layout-explorer');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerIndexLayout)`${LayoutStyles}`,
};

export const PageLayoutExplorer: PageLayoutComponent = 
(props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
    <SiteHeaderProvider value={{
      variant: 'default',
    }}>
    {/* <ExplorerProvider> */}
    <My.Container className={cls.name(className)}>
      <My.Header />
      <My.Layout entity={entity} />
    </My.Container>
    {/* </ExplorerProvider> */}
    </SiteHeaderProvider>
  )
};